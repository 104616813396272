import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SeoMeta from "../components/seo";
import Form1 from "../static/pdf/Identification-and-Emergency-Information-LIC700.pdf";
import Form2 from "../static/pdf/Childs-Preadmission-Health-History-LIC702.pdf";
import Form3 from "../static/pdf/Personal-Rights-LIC613A.pdf";
import Form4 from "../static/pdf/Parents-Rights-LIC500.pdf";
import Form5 from "../static/pdf/Consent-For-Emergency-Medical-Treatment-LIC627.pdf";
import Form6 from "../static/pdf/Physicians-Report-LIC701.pdf";
import Form7 from "../static/pdf/Allergy-Information-Sheet.pdf";
import Form8 from "../static/pdf/Trial-Basis.pdf";
import Form9 from "../static/pdf/Authorized-Pick-Up.pdf";
export const TuitionGrid = props => {
  const grid = props.grid || [];
  const gridStyle = {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: 'auto 1fr 1fr 1fr 1fr',
    gridAutoFlow: 'column',
    gridGap: '.1rem .1rem',
    marginBottom: '1rem'
  };
  const gridItemStyle = {
    border: '1px solid gray',
    padding: '.5rem'
  };
  const gHeaderStyle = {
    border: '1px solid gray',
    backgroundColor: '#ddd',
    fontWeight: '600',
    padding: '.5rem'
  };
  return <div style={gridStyle}>
      {grid.map((row, idx) => {
      return <>
          <div style={gHeaderStyle}>{row[0]}</div>
          <div style={idx !== 0 ? gridItemStyle : gHeaderStyle}>{row[1]}</div>
        </>;
    })}
    </div>;
};
export const _frontmatter = {
  "title": "Admissions"
};
const layoutProps = {
  TuitionGrid,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SeoMeta title={props.pageContext.frontmatter.title} mdxType="SeoMeta" />
    <div className="container">
      <h3>{`A Message from the Director`}</h3>
      <p>{`Director Heidy Meneses has a message for our families. `}<a parentName="p" {...{
          "href": "/a-message-from-the-director"
        }}>{`Click here`}</a></p>
      <h3>{`Registration Forms`}</h3>
      <ul>
        <li parentName="ul">
          <a href={Form1}>Identification & Emergency Information</a>
        </li>
        <li parentName="ul">
          <a href={Form2}>Health History</a>
        </li>
        <li parentName="ul">
          <a href={Form3}>Personal Rights</a>
        </li>
        <li parentName="ul">
          <a href={Form4}>Parents’ Rights</a>
        </li>
        <li parentName="ul">
          <a href={Form5}>Consent for Medical Treatment</a>
        </li>
        <li parentName="ul">
          <a href={Form6}>Physician's Report</a>
        </li>
        <li parentName="ul">
          <a href={Form7}>Allergy Form</a>
        </li>
        <li parentName="ul">
          <a href={Form8}>Trial Basis</a>
        </li>
        <li parentName="ul">
          <a href={Form9}>Authorized Pick-up</a>
        </li>
        <li parentName="ul">{`Parent Handbook`}</li>
      </ul>
      <h3>{`Tuition`}</h3>
      <p>{`Our tuition is divided into Full and Half days.`}</p>
      <TuitionGrid grid={[['Days:', 'Monthly Rate:'], ['5 Full Days', '$995'], ['3 Full Days', '$695'], ['5 Half Days', '$735'], ['3 Half Days', '$475']]} mdxType="TuitionGrid" />
      <p><strong parentName="p">{`New Student Registration Fee (one time):`}</strong>{` $200`}</p>
      <p><strong parentName="p">{`Yearly Student Fees:`}</strong>{` $200	Preschool Student Fee*`}</p>
      <p><strong parentName="p">{`Terms:`}</strong>{` Students must be 2 years old to enroll, no exceptions. There is a non-refundable deposit of $400 that is required upon enrollment. Tuition is due on the `}<strong parentName="p">{`1st of the month`}</strong>{`. Classroom instructions begin at `}<strong parentName="p">{`9:00 AM`}</strong>{`.`}</p>
      <p><strong parentName="p">{`Potty Training:`}</strong>{` There is an additional $50 per month charge for students needing help completing potty training. The parents will provide diapers, wipes, and any ointment/creams needed.`}</p>
      <p><strong parentName="p">{`Meals & Extended Care:`}</strong>{` Full day hours are from 6:30AM to 6:30PM. Half day hours are from 6:30AM to Noon. If a student on a half day schedule remains after `}<strong parentName="p">{`12:00 PM`}</strong>{`, a fee of `}<strong parentName="p">{`$22.00`}</strong>{` will be charged to your account for each afternoon session. Full day and half day includes lunch and snacks. Valley School closes promptly at `}<strong parentName="p">{`6:30 PM`}</strong>{`. There is a charge of `}<strong parentName="p">{`$20.00`}</strong>{` for each 15 minutes or partial 15 minutes a student is left on campus after `}<strong parentName="p">{`6:30 PM`}</strong>{`.`}</p>
      <p>{`*`}<em parentName="p">{`Yearly student fees cover yearbook, earthquake kits, student accident insurance, as well as basic classroom supplies and materials. The yearly student fee does not cover textbooks.`}</em></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      